@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap");

html,
body {
  position: relative;
  max-width: 1920px;
  margin: 0 auto;
  color: #2b2934;
  // overflow-x: hidden;
  background: url("../../public/Slice\ 1\ \(15\).png");
  z-index: -10;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html,
body {
  max-width: 1920px;
  width: 100vw;
  // overflow-x: hidden;
  font-family: "Inter", sans-serif;
  font-display: swap !important;
  margin: 0 auto;
  color: #2b2934;
}

body {
  overflow-x: hidden;
}

a {
  color: inherit;
  text-decoration: none;
}

.tag {
  padding: 4px 12px;
  text-align: center;
  background: #eaf3fe;
  mix-blend-mode: multiply;
  border-radius: 16px;
  width: max-content;
  span {
    font-weight: 500;
    font-size: 14px;
    background: linear-gradient(116.56deg, #2665ff 0%, #36b1f2 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
  }
}

.gradient-text {
  background: linear-gradient(116.56deg, #2665ff 0%, #36b1f2 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.heading {
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 44px;
  margin-bottom: 24px;
  color: #101828;
}

.sub-heading {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  color: #475467;
  text-align: center;
}

@media (max-width: 480px) {
  .header {
    padding: 0px 16px;
    margin: 174px 0px 0px 0px;
    .sub-heading {
      text-align: center;
    }
  }
}

.card-title {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  margin: 20px 0px 8px 0px;
  line-height: 24px;
}

.card-sub-title {
  color: #475467;
  font-weight: 400;
  line-height: 24px;
}
@media (max-width: 480px) {
  .card-title {
    font-weight: 600;
    text-align: center;
  }
  .card-sub-title {
    text-align: center;
    color: #475467;
    font-weight: 400;
  }
}

/* Target the scrollbar track (the background area) */
::-webkit-scrollbar {
  width: 12px; /* Adjust width */
}

/* Target the scrollbar track */
::-webkit-scrollbar-track {
  background: #f1f1f1; /* Background color of the scrollbar track */
  border-radius: 10px;
}

/* Target the scrollbar thumb (the draggable part) */
::-webkit-scrollbar-thumb {
  background-color: #808080; /* Color of the thumb */
  border-radius: 10px;
  border: 3px solid #f1f1f1; /* Add space around the thumb */
}

/* Hover effect for the scrollbar thumb */
::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}
